.menu-dropdown {
    display: inline-block;
}

.menu-open, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus {
    background: #fff;
    background-image: url('../img/menu-open.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 34px;
    height: 28px;
    border: 0;
    image-rendering: pixelated;/* keeps it looking crisp at any size */
    box-shadow: 0px 7px 17px 0px rgba(0,0,0,0.25);
    border-radius: 4px;
}

.menu-open:hover, .menu-open:focus {
    background: rgb(245, 146, 144);
    background-image: url('../img/menu-open-hover.png');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
}

.menu-open:active, .menu-open:active:focus  {
    background: rgb(245, 146, 144);
    background-image: url('../img/menu-open-hover.png');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
}