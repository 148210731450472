.qa {
	h2 {
		border: 2px solid $forth;
		border-radius: 30px 0 0 0;
		margin-top: 30px;
		margin-bottom: 0;
		padding: 15px;
		padding-left: 30px;
	}

	.scroll {
		height: 60vh;
		background-color: $forth;
		margin-bottom: 30px;
		overflow-y: scroll;
		overflow-x: hidden;
		position: relative;
	}
}


.comment {
	width: 100%;
	display: inline-block;

	.contain {
		opacity: 0;
		background-color: #e6e7ed;
		display: inline-block;
		margin-left: 30px;
		margin-top: 30px;
		border-radius: 15px;
		padding-left: 15px;
		padding-right: 15px;
		position: relative;
		transition: all 0.5s;

		max-width: 75%;

		p {
			margin: 0;
			opacity: 0;
		}

		span {
			opacity: 0;
			height: 0;
    		font-size: 0;
    		position: absolute;
		}
	
		.bubbles {
			position: absolute;
			left: -15px;
			bottom: -5px;
			background-color: #e6e7ed;
			width: 15px;
			height: 15px;
			border-radius: 50%;
		}
	}

	.from {
		opacity: 0;
		margin-left: 45px;
	}

	&__dots {

		.contain {
			opacity: 1;
			height: 2.5em;

			p {
				height: 0;
				width: 0;
			}

			span {
				font-size: 60px;
				line-height: 1px;
				height: initial;
				position: relative;

				animation-name: blink;
				animation-duration: 1.4s;
				animation-iteration-count: infinite;
				animation-fill-mode: both;

				opacity: 1;

				&:nth-child(2) {
					animation-delay: .2s;
				}

				&:nth-child(3) {
					animation-delay: .4s;
				}
			}
		}
	}

	&--right {
		.contain {
			margin-right: 30px;
			margin-left: 0;
			float: right;

			.bubbles {
				left: initial;
				right: -15px;
				border-radius: 50%;
			}
		}
	}

	&__question {
		.contain {
			opacity: 1;
			height: initial;
			padding: 20px;

			p {
				opacity: 1;
			}
		}
		.from {
			opacity: 1;
		}
	}

	&__answer {
		.contain {
			opacity: 1;
			height: initial;
			padding: 20px;
			margin-right: 30px;
			margin-left: 25%;
			float: right;

			.bubbles {
				left: initial;
				right: -15px;
				border-radius: 50%;
			}

			p {
				opacity: 1;
			}
		}

		.from {
			opacity: 1;
			margin-right: 50px;
			float: right;
		}
	}
}

@keyframes blink {
	0% {
		opacity: .2;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: .2;
	}
}