@mixin homepage-boxes($panel-bg) {
    background-color: $panel-bg;
    box-shadow: 0px 12px 20px 0px rgba(0,0,0,0.20);
    border-radius: 6px;
}

@mixin background-image-retina($file, $type, $width, $height) {
	background-image: url($file + '.' + $type);
	@media only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (-moz-min-device-pixel-ratio: 2),
		only screen and (-o-min-device-pixel-ratio: 2/1),
		only screen and (min-device-pixel-ratio: 2),
		only screen and (min-resolution: 192dpi),
		only screen and (min-resolution: 2dppx){
		& {
			background-image: url($file + '@2x.' + $type);
			-webkit-background-size: $width $height;
			-moz-background-size: $width $height;
			-o-background-size: $width $height;
			background-size: $width $height;
		}
	}
}