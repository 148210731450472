@import "mixins.scss";

$background: #F6F6F6;

$default: #58BFF8;
$primary: #FACC64;
$second: #E58886;
$third: #6CC2BF;
$forth: #F2F2F2;
$fifth: #D7D9E2;
$sixth: #85878D;
$seventh: #F8F8F8;
$eighth: #6CC2BF;
$ninth: #AF86E4;

$fb_blue: #3B5998;

$alternative: #4C5165;
$offgrey: #D8D8D8;

$btn-primary-color: $background;
$btn-primary-bg: $default;
$btn-primary-border: $default;

$default-font: #9B9B9B;

$star-width: 38px;
$star-height: 46px;
$star-bg-width: 56px;
$star-bg-height: 55px;

@import "public/vendor/bootstrap-sass/assets/stylesheets/bootstrap";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css";
@import "menu.scss";
@import "select2-bestforbaby.scss";

@import 'pages/qa';

@font-face {
    font-family: Aachen;
    src: url('../fonts/Aachen Medium Plain.ttf');
}

@font-face {
    font-family: OpenSans Regular;
    src: url('../fonts/OpenSans-Regular.ttf');
}


body {
    background: white;
    color: $default-font;
    font-family: OpenSans Regular;
    font-size: 16px;
}

h1, h2, h4, h5, .lead {
    color: $alternative;
    font-family: Aachen;
}

h3 {
    font-family: OpenSans Regular;
    color: $alternative;
    font-weight: bold;
}

h1 small {
    display: block;
}

.breadcrumb {
    background: none;
    font-size: 12px;
    margin-bottom: 10px;
}

.header {
    background: url('../img/babies.png') no-repeat right top;
    position: relative;
    z-index: 100;

    @media(max-width: $screen-xs-max) { 
    	background: url('../img/babies-mobile.png') no-repeat right top;
    	background-size: 304px;
        height: 220px;

        .logo {
            height: 145px;
        }
    }

    @media (min-width: $screen-sm-min) {
        height: 170px;
        background-size: 615px 138px;

        .logo {
            height: 115px;
        }
    }

    @media (min-width: $screen-md-min) {
        height: 200px;
        background-size: 768px 172px;

        .logo {
            height: 135px;
        }
    }

    @media (min-width: $screen-lg-min) {
        height: 252px;
        background-size: auto;

        .logo {
            height: auto;
        }
    }

    .row {
        margin-top: 5px;
    }

    .menu-buttons {
        margin-top: 15px;

        .sign-in {
            padding: 5px 15px;
            @media (min-width: $screen-md-min) {
                padding: 5px 35px;
            }
            box-shadow: 0px 7px 17px 0px rgba(0,0,0,0.25);
            border-radius: 4px;
        }

    }


}

.search-banner {
    background: $primary;
    position: relative;
    z-index: 50;
    margin-top: -52px;

    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width: $screen-sm-min) {
        padding: 30px;
    }
    
    h2 {
        color: $alternative;
        font-size: 22px;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
        }
    }

    .btn {
        background: $alternative;
        color: $background;
    }

    .brand-thumbnails {
        max-width: 100px;
    }

    .search {
        box-shadow: 0px 5px 18px 0px rgba(0,0,0,0.20);
        border-radius: 8px;
        font-size: 18px;
    }

    @media (max-width: $screen-sm-min) {
        .dropdown-menu-search {
            width: 290px;

            li {
                a {
                    white-space: normal;
                }
            }
        }
    }
}

.news-image {
    min-width: 70px;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}

.star-rating {
    .star {
        width: 33px;
        height: 55px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: -10px;

        &.white {
            background-image: url('../img/star-white.png');
        }

        &.blank {
            background-image: url('../img/star-blank.png');
        }
    }
}

.homepage-banners {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;

    .btn {
        border-radius: 6px;
        padding: 8px 10px;
        display: block;
        margin-bottom: 5px;
        height: 40px;
    }

    h1 {
        margin-top: 0;
        font-size: 30px;
    }

    .box {
        min-height: 380px;
        @media (min-width: $screen-sm-min) {
            min-height: 455px;
        }

        &.one {
            margin-top: 30px;
            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }
            padding: 20px;
            color: white;
            background: url('../img/banners/LHS Banner.jpg') center no-repeat;
            background-size: contain;
            @include homepage-boxes($second);

            .btn {
                border-color: $background;
                color: $background;
                background: $second;
                position: absolute;
                left: 35px;
                bottom: 20px;
            }

            h1 {
                color: $background;
            }
        }

        &.two {
            position: relative;
            color: $alternative;
            @include homepage-boxes($background);

            .carousel {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 6px;

                .carousel-indicators {
                    bottom: 0;

                    @media (min-width: $screen-sm-min) {
                        bottom: 10px;
                        left: 20px;
                        width: auto;
                        margin-left: 0;
                    }

                    li {
                        border-color: rgba(0, 0, 0, 0.3);
                        background-color: rgba(0, 0, 0, 0.3);

                        &.active {
                            border-color: rgba(0, 0, 0, 0.7);
                            background-color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }

                .carousel-inner {
                    height: 100%;

                    .item {
                        border-radius: 6px;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        @media (min-width: $screen-sm-min) {
                            background-size: cover;
                        }

                        .logo {
                            position: absolute;
                            top: 20px;
                            left: 20px;
                        }

                        .buttons {
                            position: absolute;
                            bottom: 30px;
                            right: 20px;
                            left: 20px;

                            @media (min-width: $screen-sm-min) {
                                left: auto;

                                .btn {
                                    display: inline;
                                }
                            }
                        }

                        &.one {
                            background-image: url('../img/banners/competition-mobile.jpg');
                            @media (min-width: $screen-lg-min) {
                                background-image: url('../img/banners/competition.jpg');
                            }
                        }

                        &.two {
                            background-image: url('../img/banners/HP2-mobile.jpg');
                            @media (min-width: $screen-lg-min) {
                                background-image: url('../img/banners/HP2.jpg');
                            }
                        }

                        &.three {
                            background-image: url('../img/banners/HP1-mobile.jpg');
                            @media (min-width: $screen-lg-min) {
                                background-image: url('../img/banners/HP1.jpg');
                            }
                        }

                        &.four {
                            background-image: url('../img/banners/did you know-mobile.jpg');
                            @media (min-width: $screen-lg-min) {
                                background-image: url('../img/banners/did you know.jpg');
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar {
    margin-top: 20px;

    h3 {
        margin-top: 5px;
    }

    .btn-group {
        width: 100%;
        margin-bottom: 20px;

        .category-dropdown {
            box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.20);
            border-radius: 8px;
            width: 100%;
            text-align: left;
            position: relative;

            .glyphicon {
                position: absolute;
                right: 15px;
                line-height: 30px;
            }
        }
    }

    .box {
        color: $background;
        padding: 10px;

        h1 {
            color: $background;
            font-size: 22px;
            margin-top: 0;
        }

        .btn {
            color: $background;
            border-color: $background;
            border-radius: 20px;
            padding: 10px 20px;
            display: block;
            width: 70%;
            margin: 0 auto;
        }

        &.one {
            color: white;
            min-height: 220px;
            background: url('../img/baby-monitor.png') bottom center no-repeat;
            background-size: 90%;
            @include homepage-boxes($alternative);
            margin-bottom: 20px;

            .btn {
                background: $alternative;
            }
        }

        &.two {
            color: white;
            min-height: 200px;
            @include homepage-boxes($default);
            padding-top: 30px;
            margin-bottom: 20px;
            text-align: center;

            .btn {
                background: $default;
            }
        }
    }

    .star-rating {
        display: block;
    }

    .reviews {
        li {
            border-bottom: 1px dashed $alternative;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            padding-right: 15px;

            &:last-child {
                border-bottom: none;
            }

            &:after {
                content: "\e258";
                font-family: 'Glyphicons Halflings';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            a {
                color: $alternative;
            }
        }
    }

}

.content-section {
    margin-top: 50px;
    margin-bottom: 50px;

    .btn {
        &.top100 {
            margin-top: 10px;
            border-radius: 10px;
            border-color: $btn-primary-border;
        }
    }
}

.ranking-table {

    tr {
        background: $forth;
        box-shadow: 0px 22px 34px 0px rgba(0,0,0,0.10);
        border-radius: 0px 8px 8px 0px;
    }

    td {
        padding: 10px;
        
    }

    .brand-image-table {
        box-shadow: 0px 6px 14px 0px rgba(0,0,0,0.20);
        border-radius: 8px;
        max-height: 100px;
        max-width: 100px;
    }
}

.brand-information {
    background: $seventh;
    margin-top: 35px;

    h2 {
        margin-top: 0;
    }
}

.brand-information-tabs > li > a {
    border: 0;
    border-bottom: 3px solid transparent;
    padding: 10px 15px 10px 0;
    font-size: 18px;
    color: $alternative;
    font-family: Aachen;
}

.brand-information-tabs > li.active > a, .brand-information-tabs > li.active > a:hover, .brand-information-tabs > li.active > a:focus {
    color: #555555;
    border: 0;
    border-bottom-color: transparent;
    border-bottom: 3px solid $default;
}

.category-navigation-container {
    background: $forth;
}

.category-navigation {
    column-count: 3;
    column-gap: 20px;
    margin-bottom: 20px;

    ul {

        li {
            padding-bottom: 10px;
            margin-top: 10px;

            a {
                color: $sixth;
            }

        }

    }

}

.footer-container {
    background: $alternative;
    padding: 10px 0px 5px 0px;

    a {
        color: $forth;
    }

}

.orderby {
    font-size: 14px;

    li {
        a {
            color: $alternative;
            padding: 10px 4px;
            border-radius: 6px;
            background-color: $forth;
            margin-right: 5px;
        }

        &.active {
            a, a:hover, a:active, a:focus {
                color: #fff;
                background-color: $alternative;
            }
        }
    }
}

.brand-list {
    margin-top: 20px;

    a, div.item {
        display: block;
        background: $forth;
        border-radius: 8px;
        height: 80px;
        margin-bottom: 65px;
        @media (min-width: $screen-sm-min) {
            margin-bottom: 30px;
        }

        .btn {
            margin-top: 30px;
        }

        .row {
            &.mobile-details {
                margin-top: 10px;
            }

            .brand-list-position {
                color: $default-font;
                font-size: 20px;
                display: block;
                margin-top: 10px;

                @media (min-width: $screen-sm-min) {
                    margin-top: 25px;
                }
            }

            .brand-list-rating-text {
                padding-top: 20px;

                &.with-bar {
                    padding-top: 3px;
                }

                color: $default;

                p {
                    line-height: 15px;
                }

                &.top-100 {
                    padding-top: 5px;
                }
            }

            .brand-list-rating {
                font-size: 14px;

                .star {
                    vertical-align: middle;
                    width: $star-width * 0.75;
                    height: $star-height * 0.75;
    
                    &.full {
                        @include background-image-retina('../img/star-full', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                    }

                    &.half {
                        @include background-image-retina('../img/star-half', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                    }

                    &.empty {
                        @include background-image-retina('../img/star-empty', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                    }

                    @media (min-width: $screen-sm-min) {
                        width: $star-width;
                        height: $star-height;

                        &.full {
                            @include background-image-retina('../img/star-full', 'png', $star-bg-width, $star-bg-height);
                        }

                        &.half {
                            @include background-image-retina('../img/star-half', 'png', $star-bg-width, $star-bg-height);
                        }

                        &.empty {
                            @include background-image-retina('../img/star-empty', 'png', $star-bg-width, $star-bg-height);
                        }
                    }
                }

                @media (min-width: $screen-sm-min) {
                    padding-right: 20px;
                    font-size: 20px;
                }
            }

            .top-100-score {
        		font-size: 29px;
        		font-weight: bold;
        	}

            .brand-list-rating-bar {
                color: $alternative;
                font-size: 20px;
                @media (min-width: $screen-sm-min) {
                    padding-right: 20px;
                }

                .progress {
                    border-radius: 8px;
                    padding: 7px;
                    margin-bottom: 0;
                    display: inline-block;
                    width: 100%;
                    vertical-align: middle;
                    @media (min-width: $screen-sm-min) {
                        width: 170px;
                    }

                    .progress-bar {
                        border-radius: 4px;
                    }
                }
            }

            .brand-list-img {
                margin-top: -5px;
                margin-bottom: -5px;
                margin-left: -15px;
                border-radius: 8px;
                box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.20);
                background: #fff;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 90px;
                height: 90px;
            }

            .col-xs-2 {
            	.brand-list-img {
            		width: 95px;
            	}
            }

            .col-sm-2 {
            	.brand-list-img {
            		width: 95px;
            	}
            }

            .brand-list-name {
                color: $alternative;
                font-size: 14px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (min-width: $screen-sm-min) {
                    font-size: 20px;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }
            }

            .brand-list-count {
                color: $default;
                font-size: 12px;

                @media (min-width: $screen-sm-min) {
                    font-size: 14px;
                }
            }

            .brand-list-button {
                background: white;
                position: absolute;
                width: 100%;
                height: 100%;
                color: #000;
                text-align: center;

                .fa {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &.top {
            height: 100px;
            box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);

            margin-top: 15px;

            @media (min-width: $screen-sm-min) {
                margin-top: 25px;
            }

            &.position-1 {
                background: $primary;
            }

            &.position-2 {
                background: $second;
            }

            &.position-3 {
                background: $third;
            }

            .row {
                .brand-list-img {
                    width: 110px;
                    height: 110px;
                }

                .col-xs-2 {
	            	.brand-list-img {
	            		width: 95px;
	            	}
	            }

	            .col-sm-2 {
	            	.brand-list-img {
	            		width: 95px;
	            	}
	            }

                @media (min-width: $screen-sm-min) {
                    .brand-list-name {
                        margin-top: 20px;
                    }
                }

                .brand-list-position {
                    color: #fff;
                    margin-top: 20px;
                }

                .brand-list-count {
                    @media (min-width: $screen-sm-min) {
                        color: #fff;
                    }
                }

                .brand-list-rating-text {
                    padding-top: 30px;

                    &.with-bar {
                        padding-top: 12px;
                    }

                    &.top-100 {
                    	padding-top: 15px;
                	}

                }

                .brand-list-rating {
                    color: $alternative;

                    .star {
                        &.full {
                            @include background-image-retina('../img/star-white', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                        }

                        &.half {
                            @include background-image-retina('../img/star-half-white', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                        }

                        &.empty {
                            @include background-image-retina('../img/star-empty-white', 'png', $star-bg-width * 0.75, $star-bg-height * 0.75);
                        }

                        @media (min-width: $screen-sm-min) {
                            &.full {
                                @include background-image-retina('../img/star-white', 'png', $star-bg-width, $star-bg-height);
                            }

                            &.half {
                                @include background-image-retina('../img/star-half-white', 'png', $star-bg-width, $star-bg-height);
                            }

                            &.empty {
                                @include background-image-retina('../img/star-empty-white', 'png', $star-bg-width, $star-bg-height);
                            }
                        }
                    }
                }
            }
        }
    }
}

.brand-img {
    width: 100%;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

.brand-ribbon {
    width: 100%;
    height: 209px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: center;

    &.position-1 {
        @include background-image-retina('../img/1-award-logo', 'png', 151px, 209px);
    }

    &.position-2 {
        @include background-image-retina('../img/2-award-logo', 'png', 151px, 209px);
    }

    &.position-3 {
        @include background-image-retina('../img/3-award-logo', 'png', 151px, 209px);
    }
}

.rating-bar {
    color: $alternative;
    font-size: 20px;

    .progress {
        border-radius: 8px;
        padding: 7px;
        margin-bottom: 0;
        box-shadow: none;
        background: none;

        .progress-bar {
            box-shadow: none;
            border-radius: 4px;

            &.progress-bar-pink {
                background-color: #E68886;
            }

            &.progress-bar-black {
                background-color: $alternative;
            }

            &.progress-bar-cyan {
                background-color: #58BFF9;
            }

            &.progress-bar-purple {
                background-color: $ninth;
            }
        }
    }
}

.rating-row {
    color: #5D6477;
    margin-bottom: 15px;
    font-weight: bold;

    .rating {
        float: right;
    }
}

.brand-block-b {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);

    .padding {
        padding: 10px;
    }

    h2 {
        margin-top: 0;
        font-size: 22px;
    }

    .review-count {
        float: right;
        margin-top: 3px;
        font-size: 14px;
        color: $default;
    }

    .summary {
        margin-bottom: 15px;
        background: $forth;
        padding: 10px;
    }

    .progress-alt {
        overflow: hidden;
        margin-bottom: 10px;
        clear: both;
        font-style: italic;

        &:last-child {
            margin-bottom: 0;
        }

        .label-a {
            width: 50px;
            float: left;
            color: $alternative;
        }
        .label-b {
            width: 35px;
            text-align: right;
            float: left;
            color: $alternative;
        }

        .progress-bar-alt-inner {
            background: $primary;
            height: 5px;
            border-radius: 3px;
        }

        .progress-bar-alt {
            background: #F9F9F9;
            border-radius: 5px;
            padding: 8px;
            width: calc(100% - 90px);
            float: left;
        }
    }

}

.fb-button {
    background: $fb_blue;
    border-color: $fb_blue;
}

.fb-button:hover {
    background: $fb_blue;
    border-color: $fb_blue;
}

h1.larger {
    font-size: 32px;
    @media (min-width: $screen-md-min) {
        font-size: 50px;
    }
    margin-top: 0;
}

.larger-rating {
    text-align: center;

    .star {
        margin-right: 4px;
    }
}

.compare-btn, .compare-btn:focus {
    text-align: left;
    padding-left: 40px;
    background-position: 10px;
    background-repeat: no-repeat;
    @include background-image-retina('../img/compare', 'png', 24px, 19px);
}

.review-btn, .review-btn:focus {
    text-align: left;
    padding-left: 40px;
    background-position: 10px;
    background-repeat: no-repeat;
    @include background-image-retina('../img/pen', 'png', 17px, 17px);
}

.btn-pink, .btn-pink:focus {
    background: $second;
    border-color: $second;
    color: white;
}
.btn-pink:hover {
    background: darken($second, 10%);
    border-color: darken($second, 10%);
    color: white;
}
.btn-pink:focus:active {
    background: darken($second, 20%);
    border-color: darken($second, 20%);
}

.btn-shoppinglist, .btn-shoppinglist:hover, .btn-shoppinglist:active, .btn-shoppinglist:focus {
    text-align: left;
    padding-left: 40px;
    background-position: 10px;
    background-repeat: no-repeat;
    @include background-image-retina('../img/shopping-list', 'png', 18px, 20px);
}

.social-btns {
    margin-top: 10px;
    line-height: 34px;

    .social-btn {
        background-color: #fff;
        border-radius: 4px;
        margin-right: 5px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        width: 34px;
        height: 34px;
        background-size: 60%;

        &.facebook {
            background-image: url('../img/facebook.png');
        }

        &.twitter {
            background-image: url('../img/twitter.png');
        }
    }
}

.result-num {
    margin-top: 30px;
    font-size: 72px;
    font-weight: bold;
}

.share-container {
    background: white;
    border-radius: 6px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 204px;
    height: 44px;
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);
    text-align: center;

    img {
        margin-top: 7px;
    }

    div {
        padding-top: 10px;
    }
}

.social-icons {
    margin-bottom: 20px;
}

@media (min-width: $screen-md-min) {
    .reviews {
        border-right: 1px solid $offgrey;
        padding-right: 30px;
    }
}

.review {
    margin-top: 15px;
    padding-top: 25px;
    padding-bottom: 25px;

    p {
        color: $alternative;
        font-style: italic;
        font-weight: bold;
    }

    &:last-child {
        border-bottom: 0;
    }

    .date {
        color: $alternative;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .comment {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .row {
        margin-bottom: 20px;
    }
}

.tab-pane#info {
    margin-top: 20px;
}

.sidebar-categories {
    li {
        margin-bottom: 10px;

        a {
            color: $sixth;
            font-size: 12px;
        }
    }
}

.reviews-section {
    margin-top: 40px;

    h2 {
        margin-top: 0;
    }
}

.top-100-form {
	margin-top: 20px;
}

.review-form {
    h1 {
        margin-top: 0;
    }

    h2 {
        color: $default;
        font-family: 'OpenSans Regular', sans-serif;
        font-size: 22px;
    }

    h3 {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    .form-group.shadow {
        box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);

        .form-control {
            border-color: transparent;
            border-radius: 6px;
            box-shadow: none;
        }

        textarea {
            height: 270px;
        }

        &.has-error {
            .form-control {
                border-color: #a94442;
            }
        }
    }

    .box {
        box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);
        padding: 30px 35px;
        border-radius: 6px;

        label {
            color: $alternative;
            margin-bottom: 20px;
        }

        .br-theme-bars-movie {
            margin-bottom: 20px;
        }

        .br-theme-bars-movie .br-widget {
            display: table;
            width: 100%;
        }

        .br-theme-bars-movie .br-widget a {
            background: $offgrey;
            display: table-cell;
            width: auto;
            float: none;
            border-right: 1px solid #fff;

            &:first-child {
                border-radius: 6px 0 0 6px;
            }

            &:last-child {
                border-radius: 0 6px 6px 0;
            }
        }

        .product-performance {
            .br-theme-bars-movie .br-widget a.br-active, .br-theme-bars-movie .br-widget a.br-selected {
                background: $second;
            }
        }

        .value-for-money {
            .br-theme-bars-movie .br-widget a.br-active, .br-theme-bars-movie .br-widget a.br-selected {
                background: $eighth;
            }
        }
    }

    input[type=submit], .buttons a {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        height: 40px;
        padding: 9px 12px;
    
        @media (min-width: $screen-md-min) {
            display: inline;
            width: auto;
            margin-bottom: 0;
        }
    }

    .buttons {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    select {
        display: none;
    }
}

.br-theme-stars {
    .br-widget a {
        display: inline-block;
        width: $star-width;
        height: $star-height;
        background-repeat: no-repeat;
        background-position: center top;
        @include background-image-retina('../img/star-empty', 'png', $star-bg-width, $star-bg-height);

        &.br-active, &.br-selected, &.br-current {
            @include background-image-retina('../img/star-full', 'png', $star-bg-width, $star-bg-height);
        }
    }
}

.star {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center top;

    width: $star-width;
    height: $star-height;

    &.full {
        @include background-image-retina('../img/star-full', 'png', $star-bg-width, $star-bg-height);
    }

    &.half {
        @include background-image-retina('../img/star-half', 'png', $star-bg-width, $star-bg-height);
    }

    &.empty {
        @include background-image-retina('../img/star-empty', 'png', $star-bg-width, $star-bg-height);
    }
}

.rating-stars {
    .star {
        width: $star-width * 0.75;
        height: $star-height * 0.75;
        background-size: ($star-bg-width * 0.75) ($star-bg-height * 0.75);
    }
}

.rating-bar {
    display: table;
    width: 100%;

    .block {
        display: table-cell;
        height: 4px;
        background-color: $offgrey;
        border-right: 1px solid #fff;

        &:first-child {
            border-radius: 6px 0 0 6px;
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
        }
    }

    &.value-for-money .block.full {
        background-color: $eighth;
    }

    &.product-quality .block.full {
        background-color: $second;
    }

    &.awareness .block.full {
        background-color: $alternative;
    }

    &.loyalty .block.full {
        background-color: $default;
    }

    &.popularity .block.full {
        background-color: $ninth;
    }
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
}

.mark-helpful {
    color: $alternative;
    margin-left: 10px;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}
.competition-top-row {
    background: #F2F2F2;
    .btn {
        color: #F6F6F6;
        background-color: #58BFF8;
        border-color: #58BFF8;
    }
    .fb-button {
        background: #3B5998;
        border-color: #3B5998;
    }
}

.competition-form {
    padding-top: 30px;
    padding-bottom: 30px;
}


.uploaded-img {
    max-width: 200px;
}

.summary-width {
    width: 70%;
}